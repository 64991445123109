import React from 'react';
import Reveal from 'react-reveal';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import urls from '../../data/urls';

const ResearchAndDevelopment = () => {
  const imgData = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "services-randd"}) {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
    }
  `);
  const image = imgData.contentfulAsset.fluid;

  return (
    <div>
      <section className="sec_pad" id="features">
        <div className="container">
          <div className="row flex-row-reverse">
            <div className="col-lg-4 d-flex align-items-center">
              <Img
                fluid={image}
                className="w-75 mx-auto"
              />  
            </div>
            <div className="col-lg-8">
              <Reveal bottom cascade>
                <div className="app_featured_content">
                  <h2 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">Stay Ahead of the Pack</h2>
                  <h5>Achieve efficient cycle times and bring your vision to market before your competitors by brainstorming with the best. OPNA is a collective of creative minds, hungry for the next challenge.</h5>
                  <Link to={urls.contactUs} className="learn_btn_two">
                    Get a Quote!
                    <i className="ti-arrow-right" />
                  </Link>
                  <p className="f_400">
                    Your parameters don’t have to be your constraints. From martini making robots to smart products with automated sensors, our lead engineers have worked with R&D teams across many industries and companies of all sizes, from Fortune 100s to Angel Stage Start-Ups.
                    <br />
                    <br />
                    Give us your end-goal and we’ll implement an Agile methodology to get you there. We’ll run the research to ensure your product meets your customers’ needs, develop the right solutions to overcome existing obstacles and help your product break the mould.
                    <br />
                    <br />
                    We love a challenge and excel in rapid prototyping where our creativity can be unleashed. Our strategies for effective ideation mean less time going back to the drawing board and more time developing and testing quality products.
                  </p>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ResearchAndDevelopment;
